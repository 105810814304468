import PercentIcon from '@mui/icons-material/Percent';

import checkList from '../../../assets/svgs/checklist.svg';
import checkListSelected from '../../../assets/svgs/checklist_selected.svg';
import eventAvailable from '../../../assets/svgs/event_available.svg';
import eventAvailableSelected from '../../../assets/svgs/event_available_selected.svg';
import formatListBulletedAdd from '../../../assets/svgs/format_list_bulleted_add.svg';
import formatListBulletedAddSelected from '../../../assets/svgs/format_list_bulleted_add_selected.svg';
import noteStack from '../../../assets/svgs/note_stack.svg';
import noteStackSelected from '../../../assets/svgs/note_stack_selected.svg';
import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';
import { IVerticalTabItem } from '../../../components/AppVerticalTabs';
import { FilterItems } from '../../reports/types/reports.types';
import { SupportCardProps } from '../components/SupportCard';

export const DISCOUNTS_SUPPORT_CARD_DATA: SupportCardProps = {
  title: 'Need help?',
  body: 'Learn more about discounts and how to create one.',
  button: 'Help & Support',
  link: 'https://support.eatme.sg/',
};

export enum DISCOUNT_NAVIGATION_TABS {
  DISCOUNTS = 'discounts',
  //TODO: Hidden temporally DISCOUNT_REASONS = 'discount_reasons',
}

export const DISCOUNT_TABS: IVerticalTabItem[] = [
  {
    id: DISCOUNT_NAVIGATION_TABS.DISCOUNTS,
    label: 'Discounts',
    path: '/discounts/all-discounts',
    Icon: PercentIcon,
  },
  //TODO: Hidden temporally {
  //   id: DISCOUNT_NAVIGATION_TABS.DISCOUNT_REASONS,
  //   label: 'Discount Reasons',
  //   path: '/discounts/discount-reasons',
  //   Icon: ClearAllIcon,
  // },
];

export enum DISCOUNT_STEP_ID {
  DISCOUNT_DETAILS = 'discount-details',
  APPLIED_TO = 'applied-to',
  AVAILABILITY = 'availability',
  ADVANCED = 'advanced',
}

export const CREATE_DISCOUNT_STEPS = [
  {
    id: DISCOUNT_STEP_ID.DISCOUNT_DETAILS,
    Icon: checkList,
    SelectedIcon: checkListSelected,
    title: 'Discount Details',
    path: '/discounts/create/discount-details',
  },
  {
    id: DISCOUNT_STEP_ID.APPLIED_TO,
    Icon: formatListBulletedAdd,
    SelectedIcon: formatListBulletedAddSelected,
    title: 'Applied To',
    path: '/discounts/create/applied-to',
  },
  {
    id: DISCOUNT_STEP_ID.AVAILABILITY,
    Icon: eventAvailable,
    SelectedIcon: eventAvailableSelected,
    title: 'Availability',
    path: '/discounts/create/availability',
  },
  {
    id: DISCOUNT_STEP_ID.ADVANCED,
    Icon: noteStack,
    SelectedIcon: noteStackSelected,
    title: 'Advanced',
    path: '/discounts/create/advanced',
  },
];

export const DISCOUNTS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '100px',
      maxWidth: '100px',
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'type',
    header: 'TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'applyType',
    header: 'APPLIED TO',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'value',
    header: 'VALUE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'createdBy',
    header: 'CREATED BY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'createdAt',
    header: 'CREATED ON',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DISCOUNT_REASONS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  {
    accessorKey: 'description',
    header: 'DESCRIPTION',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '400px',
      maxWidth: '400px',
    },
  },
  {
    accessorKey: 'createdBy',
    header: 'CREATED BY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'createdAt',
    header: 'CREATED ON',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DISCOUNT_TYPES_FILTER_ITEMS: FilterItems[] = [
  { label: 'Fixed %', value: 'FIXED_PERCENTAGE' },
  { label: 'Fixed $', value: 'FIXED_VALUE' },
  { label: 'Open %', value: 'OPEN_PERCENTAGE' },
  { label: 'Open $', value: 'OPEN_VALUE' },
];

export const DISCOUNT_APPLY_TYPES__FILTER_ITEMS: FilterItems[] = [
  { label: 'Any Item', value: 'ANY_ITEM' },
  { label: 'Full Order', value: 'FULL_ORDER' },
  { label: 'Specific Item', value: 'SPECIFIC_ITEM' },
];
