import {
  APP_COLUMN_TYPES,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import {
  DISCOUNT_APPLY_TYPES,
  DISCOUNT_STATUS,
  DISCOUNT_TYPES,
} from '../../../constants';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import { ACTION_KEYS } from '../../menu/constants';
import {
  IDiscountReasonsResponseDTO,
  IDiscountReasonsTable,
} from '../types/discount-reasons.types';
import {
  IDiscountsResponseDTO,
  IDiscountsTable,
} from '../types/discounts.types';

const getStatusText = (status: string): string => {
  switch (status) {
    case DISCOUNT_STATUS.ACTIVE:
      return 'Active';
    case DISCOUNT_STATUS.INACTIVE:
      return 'Inactive';
    case DISCOUNT_STATUS.EXPIRED:
      return 'Expired';
    default:
      return 'Unknown';
  }
};

const getStatusChipData = (
  value: string,
): {
  color?: string;
  backgroundColor?: string;
  sx?: React.CSSProperties;
} => {
  switch (value) {
    case DISCOUNT_STATUS.ACTIVE:
      return {
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      };

    case DISCOUNT_STATUS.EXPIRED:
      return {
        backgroundColor: Colors.chipFailedBackground,
        color: Colors.chipFailedText,
        sx: { borderColor: Colors.chipFailedText },
      };

    default:
      return {
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };
  }
};

const getDiscountTypeText = (value: string) => {
  switch (value) {
    case DISCOUNT_TYPES.FIXED_PERCENTAGE:
      return 'Fixed %';
    case DISCOUNT_TYPES.FIXED_VALUE:
      return 'Fixed $';
    case DISCOUNT_TYPES.OPEN_PERCENTAGE:
      return 'Open %';
    case DISCOUNT_TYPES.OPEN_VALUE:
      return 'Open $';
    default:
      return '-';
  }
};

const getDiscountApplyTypeText = (value: string) => {
  switch (value) {
    case DISCOUNT_APPLY_TYPES.FULL_ORDER:
      return 'Full Order';
    case DISCOUNT_APPLY_TYPES.ANY_ITEM:
      return 'Any Item';
    case DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM:
      return 'Specific Item';
    default:
      return '-';
  }
};

const getDiscountValue = (value: number, currency: string, type: string) => {
  const discountValue = decimalCalculations(value).toDecimal();
  if (Number(discountValue) === 0) {
    return 'N/A';
  }

  switch (type) {
    case DISCOUNT_TYPES.FIXED_PERCENTAGE:
      return `${discountValue}%`;
    case DISCOUNT_TYPES.FIXED_VALUE:
      return decimalCalculations(value).toDecimal().toMoney(currency);
    default:
      return `${discountValue}`;
  }
};

export const getFormattedDiscounts = (
  discount: IDiscountsResponseDTO,
  currency?: string,
) => {
  const row: IAppTableRow<IDiscountsTable> = {
    key: discount._id,
    id: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: discount._id.slice(-5).toUpperCase(),
      },
    },
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: discount.name,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(discount.status),
        ...getStatusChipData(discount.status),
      },
    },
    type: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getDiscountTypeText(discount.type),
      },
    },
    applyType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getDiscountApplyTypeText(discount.applyType),
      },
    },
    value: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getDiscountValue(
          discount.value,
          String(currency),
          discount.type,
        ),
      },
    },
    createdBy: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: discount.createdByName,
      },
    },
    createdAt: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: `${discount.createdAt}`,
        disableLocalTime: true,
      },
    },
    subRows: [],
    actions: [],
  };

  switch (discount.status) {
    case DISCOUNT_STATUS.ACTIVE:
      row.actions.push(
        {
          key: ACTION_KEYS.EDIT,
          name: 'Edit',
        },
        {
          key: ACTION_KEYS.DEACTIVATE,
          name: 'Deactivate',
        },
      );
      break;

    case DISCOUNT_STATUS.INACTIVE:
      row.actions.push({
        key: ACTION_KEYS.ACTIVATE,
        name: 'Activate',
      });
      break;

    default:
      row.actions.push({
        key: ACTION_KEYS.EDIT,
        name: 'Edit',
      });

      break;
  }

  return row;
};

export const getFormattedDiscountReasons = (
  discountReason: IDiscountReasonsResponseDTO,
) => {
  const row: IAppTableRow<IDiscountReasonsTable> = {
    key: discountReason._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: discountReason.reason,
        customStyle: {
          whiteSpace: 'normal',
        },
      },
    },
    description: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          discountReason.description === ''
            ? ' - '
            : discountReason.description,
        customStyle: {
          whiteSpace: 'normal',
        },
      },
    },
    createdBy: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: discountReason.createdByName,
        customStyle: {
          whiteSpace: 'normal',
        },
      },
    },
    createdAt: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: `${discountReason.createdAt}`,
        disableLocalTime: true,
      },
    },
    subRows: [],
    actions: [],
  };

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
  });

  return row;
};
