import PercentIcon from '@mui/icons-material/Percent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppCard from '../../../components/AppCard';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppFilterChip from '../../../components/AppFilterChip';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTable from '../../../components/AppTable';
import {
  FILTER_TYPE,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import { DISCOUNT_STATUS } from '../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationState,
} from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { ACTION_KEYS } from '../../menu/constants';
import ViewLayout from '../../menu/hocs/ViewLayout';
import { ENTITY_SORT } from '../../menu/types/common.types';
import { FilterItems } from '../../reports/types/reports.types';
import DiscountsNoRecords from '../components/DiscountsNoRecords';
import {
  DISCOUNT_APPLY_TYPES__FILTER_ITEMS,
  DISCOUNT_TYPES_FILTER_ITEMS,
  DISCOUNTS_COLUMNS,
} from '../constants';
import {
  setDiscountsPaginationLimit,
  setDiscountsPaginationSkip,
} from '../redux/discountsPaginationSlice';
import {
  fetchDiscountsData,
  fetchDiscountsDataStatus,
  patchUpdateDiscountsData,
  setDiscountsAppliedToTypes,
  setDiscountsTypes,
} from '../redux/discountsSlice';
import {
  IDiscountsResponseDTO,
  IDiscountsTable,
} from '../types/discounts.types';
import { getFormattedDiscounts } from '../utils/formatting.utils';

const DiscountsPage = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { openConfirmationPopup } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const {
    discountsData,
    refreshing,
    extras,
    selectedDiscountsTypes,
    selectedDiscountsAppliedToTypes,
    discountSDataStatus,
  } = useAppSelector(selectDiscounts);
  const [selectedFilter, setSelectedFilter] = useState<
    DISCOUNT_STATUS | undefined
  >();

  const { user, restaurant } = useAppSelector(selectAuth);

  const discountsTypes: FilterItems[] = DISCOUNT_TYPES_FILTER_ITEMS;

  const discountsAppliedToTypes: FilterItems[] =
    DISCOUNT_APPLY_TYPES__FILTER_ITEMS;

  const { onChange, pageIndex, pageSize, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const fetchDiscountsDataCallback = useCallback(async () => {
    dispatch(fetchDiscountsDataStatus());
    dispatch(setDiscountsPaginationSkip(pageIndex * pageSize));
    dispatch(setDiscountsPaginationLimit(pageSize));
    dispatch(
      fetchDiscountsData({
        sort_order: ENTITY_SORT.ASC,
        status: selectedFilter,
        type: selectedDiscountsTypes.join(', '),
        applyType: selectedDiscountsAppliedToTypes.join(', '),
      }),
    );
  }, [
    dispatch,
    pageIndex,
    pageSize,
    selectedDiscountsAppliedToTypes,
    selectedDiscountsTypes,
    selectedFilter,
  ]);

  useEffect(() => {
    fetchDiscountsDataCallback();
  }, [fetchDiscountsDataCallback]);

  const formattedDiscountsData = useMemo(() => {
    return discountsData.map((discount: IDiscountsResponseDTO) => {
      return getFormattedDiscounts(
        discount,
        String(restaurant?.posCurrencySymbol),
      );
    });
  }, [discountsData, restaurant?.posCurrencySymbol]);

  const handleClickCreate = () => {
    navigate('/discounts/create/discount-details');
  };

  const handleSelectedFilterClick = (filter: DISCOUNT_STATUS | undefined) => {
    setSelectedFilter(filter);
  };

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);
  const handleOnClickAction = async (
    key: string,
    data: IAppTableRow<IDiscountsTable>,
  ) => {
    const foundItem = discountsData.find((item) => item._id === data.key);
    switch (key) {
      case ACTION_KEYS.ACTIVATE: {
        const response = await dispatch(
          patchUpdateDiscountsData({
            _id: data.key,
            data: {
              status: DISCOUNT_STATUS.ACTIVE,
              updatedBy: Number(user?.empId),
            },
          }),
        );
        if (response.meta.requestStatus === 'fulfilled') {
          openSnackbar(
            'Discount status has been updated Successfully!',
            SNACKBARTYPE.SUCCESS,
          );
        }
        await fetchDiscountsDataCallback();
        break;
      }

      case ACTION_KEYS.DEACTIVATE: {
        openConfirmationPopup({
          heading: `Deactivate ${foundItem?.name}`,
          content: (
            <Box>
              {'Are you sure you want to deactivate '}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {foundItem?.name}?
              </Typography>
            </Box>
          ),
          confirmButtonTitle: 'Deactivate',
          onConfirm: async () => {
            const response = await dispatch(
              patchUpdateDiscountsData({
                _id: data.key,
                data: {
                  status: DISCOUNT_STATUS.INACTIVE,
                  updatedBy: Number(user?.empId),
                },
              }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
              openSnackbar(
                'Discount status has been updated Successfully!',
                SNACKBARTYPE.SUCCESS,
              );
            }
            await fetchDiscountsDataCallback();
          },
        });
        break;
      }

      case ACTION_KEYS.EDIT:
        handleOnClickRow(data);
        break;
      default:
        break;
    }
  };

  const handleOnClickRow = async (data: IAppTableRow<IDiscountsTable>) => {
    navigate(`/discounts/edit/${data.key}`);
  };

  const handleDiscountsTypeFilterClick = (value: string[]) => {
    dispatch(setDiscountsTypes(value));
  };

  const handleDiscountsTypeAppliedToFilterClick = (value: string[]) => {
    dispatch(setDiscountsAppliedToTypes(value));
  };

  return (
    <Box sx={{ marginTop: '25x' }}>
      <ViewLayout
        title="Discounts"
        buttonText="Create Discount"
        onClickCreate={handleClickCreate}>
        <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
          <AppFilterChip
            chipHeader="Filter by Type"
            title={'Type'}
            data={discountsTypes}
            value={selectedDiscountsTypes ?? []}
            onSelect={handleDiscountsTypeFilterClick}
            type={FILTER_TYPE.CHECKBOX}
            anchorLeft
            disabled={!discountSDataStatus?.haveDiscounts}
          />
          <Box sx={{ ml: 1.2 }} />
          <AppFilterChip
            chipHeader="Filter by Applied to"
            title={'Applied to'}
            data={discountsAppliedToTypes}
            value={selectedDiscountsAppliedToTypes ?? []}
            onSelect={handleDiscountsTypeAppliedToFilterClick}
            type={FILTER_TYPE.CHECKBOX}
            anchorLeft
            disabled={!discountSDataStatus?.haveDiscounts}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '8px',
            my: 1,
          }}>
          <AppCard
            title="All"
            selected={selectedFilter === undefined}
            onSelect={() => {
              onChange(0);
              handleSelectedFilterClick(undefined);
            }}
            sx={{
              height: '50px',
            }}
          />
          <AppCard
            title="Active"
            selected={selectedFilter === DISCOUNT_STATUS.ACTIVE}
            onSelect={() => {
              onChange(0);
              handleSelectedFilterClick(DISCOUNT_STATUS.ACTIVE);
            }}
            sx={{
              height: '50px',
            }}
          />
          <AppCard
            title="Inactive"
            selected={selectedFilter === DISCOUNT_STATUS.INACTIVE}
            onSelect={() => {
              onChange(0);
              handleSelectedFilterClick(DISCOUNT_STATUS.INACTIVE);
            }}
            sx={{
              height: '50px',
            }}
          />
          <AppCard
            title="Expired"
            selected={selectedFilter === DISCOUNT_STATUS.EXPIRED}
            onSelect={() => {
              onChange(0);
              setSelectedFilter(DISCOUNT_STATUS.EXPIRED);
            }}
            sx={{
              height: '50px',
            }}
          />
        </Box>
        <AppTable
          refreshing={refreshing}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          pagination={{
            total: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
          }}
          columns={DISCOUNTS_COLUMNS}
          rows={formattedDiscountsData}
          enableRowActions
          onClickRow={handleOnClickRow}
          onClickActions={handleOnClickAction}
          emptyComponent={
            <DiscountsNoRecords
              title={
                discountSDataStatus?.haveDiscounts === false
                  ? 'No discounts added'
                  : 'No discounts found'
              }
              icon={PercentIcon}
            />
          }
        />
      </ViewLayout>
    </Box>
  );
};

export default DiscountsPage;
