import { SvgIconProps } from '@mui/material/SvgIcon';

import AppEmptyTable from '../../../components/AppEmptyTable';
import { Colors } from '../../../theme/colors';

interface DiscountsNoRecordsProps {
  title: string;
  icon: React.ComponentType<SvgIconProps>;
}

const DiscountsNoRecords = ({
  title,
  icon: IconComponent,
}: DiscountsNoRecordsProps) => {
  return (
    <AppEmptyTable
      title={title}
      icon={<IconComponent sx={{ color: Colors.primary }} />}
    />
  );
};

export default DiscountsNoRecords;
